import { Component, Vue, Prop } from 'vue-property-decorator'

import {
  Icons
} from '@/components/base';
  
@Component({
  components: {
    Icons
  }
})
export default class CardBtn extends Vue {
  @Prop({default: ''}) iconLibrary!: string;
  @Prop({default: ''}) title!: string;
  @Prop({default: 'clipboard'}) iconName!: string;
  @Prop({default: false}) isSubtitle!: boolean;


  getLibrary(): string{
    return (this.iconLibrary == '' || this.iconLibrary == 'mdi') ? 'mdi' : 'fas';
  }

}
  