import { Component, Vue } from 'vue-property-decorator';
import http from '@/services/api';
import { mountUrlWP } from '@/utils/mountURL';
import posts from '@/modules/posts/services/posts';
import Title1 from '@/components/title1/title1.vue';
  
@Component({
  components: {
    Title1
  }
})
export default class NotaValePremios extends Vue {
  isShow = false;
  imgLink = '';
  getPostUrlImage = posts.getThumbnail

  mounted() {
    this.getContent();
  }

  getContent(){
    let url = '/get_page/?slug=nota-premio';
    url = mountUrlWP(url);
    http.get(url)
      .then( (response) => {
        console.log(response.data);
        let imgLink = (response.data as any).page.thumbnail;
        imgLink = imgLink.replaceAll('http://duunoayo6b0nybm.laranjadaterra.es.gov.br', 'https://aps.laranjadaterra.es.gov.br');
        this.imgLink = imgLink;
        this.isShow = true;
      })
      .catch( (error) => {
        console.log(error);
        this.isShow = false;
      });
      
  }
}
  